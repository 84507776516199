export default function () {
    const locationInfo = useStateLocationInfo();
    const currencies = useStateCurrencyInfo();

    return computed<CurrencyInfo | null>({
        get: () =>
            (currencies.value ? currencies.value.find((c) => c.Code == locationInfo.value.CurrencyCode) : null) ?? null,
        set: async (currency: CurrencyInfo | null) => {
            if (!currency) return null;

            const { Code: code } = currency;
            const body = new FormData();
            body.append("CurrencyCode", code);
            await $fetch("/api/locale-service/SetCurrency", { method: "POST", body });
            locationInfo.value.CurrencyCode = code;
            reloadNuxtApp({ force: true });
        },
    });
}
