type AccountLinkItem = { href: string; title: string; class?: string };

export default function () {
    const { t } = useI18n();
    const localePath = useLangPath();
    const userInfo = useStateUserInfo();

    return computed<AccountLinkItem[]>(() =>
        [
            { href: localePath("/my-account"), title: t("Layout.AccountDetails") },
            userInfo.value.IsAdmin ? { href: "/administration", title: t("Layout.LinkAdministration") } : null,
            userInfo.value.IsDistributor
                ? { href: localePath("/distributor/landing"), title: t("Layout.LinkDistributorLanding") }
                : null,
            userInfo.value.IsManufacturer
                ? { href: localePath("/manufacturer/landing"), title: t("Layout.LinkManufacturerLanding") }
                : null,
            userInfo.value.HasLeadTimeReporting
                ? { href: localePath("/lead-time-reporting"), title: t("Layout.LinkReporting") }
                : null,
            { href: localePath("/logout"), title: t("Layout.LinkSignOut"), class: "font-bold" },
        ].filter((x) => !!x)
    );
}
