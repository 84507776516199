<template>
    <DropdownMenu>
        <HeaderMenuTrigger data-test-id="currency-dropdown-button">
            {{ currentCurrency.DisplayName }}
        </HeaderMenuTrigger>
        <DropdownMenuContent>
            <DropdownMenuItem
                v-for="item of currencies"
                :id="'currency-option-' + item.Code"
                :key="item.Code"
                class="link-gray justify-center"
                @click="currentCurrency = item">
                {{ item.DisplayName }}
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
<script setup>
const currencies = useStateCurrencyInfo();
const currentCurrency = useCurrentCurrency();
</script>
