<template>
    <DropdownMenuSub>
        <DropdownMenuSubTrigger data-test-id="currency-dropdown-button">
            {{ currentCurrency.DisplayName }}
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
            <DropdownMenuSubContent>
                <DropdownMenuItem
                    v-for="item of currencies"
                    :id="'currency-option-' + item.Code"
                    :key="item.Code"
                    class="link-gray"
                    @click="currentCurrency = item">
                    {{ item.DisplayName }}
                </DropdownMenuItem>
            </DropdownMenuSubContent>
        </DropdownMenuPortal>
    </DropdownMenuSub>
</template>
<script setup>
const currencies = useStateCurrencyInfo();
const currentCurrency = useCurrentCurrency();
</script>
