<template>
    <div class="input-group input-group-with-button w-full" :class="props.isHome ? 'h-20 rounded-md' : 'h-12'">
        <Autocomplete
            id="searchText"
            v-model="searchText"
            :placeholder="props.placeholder"
            :headers="headers"
            :autofocus="props.isHome"
            select-on-focus
            custom-list-class="search-menu-list"
            custom-main-class="h-full w-full grow"
            :custom-container-class="props.isHome ? 'home-search-dropdown' : 'search-dropdown top-12'"
            :custom-class="
                props.isHome
                    ? 'text-2xl !rounded-tl-md !rounded-bl-md px-6 border-white focus:border-white focus:ring-transparent'
                    : 'text-lg rounded border-white focus:border-white focus:ring-transparent'
            "
            show-min-length-message
            :results="searchResults"
            @search="onSearch"
            @select="onSelect"
            @enter="executeSearch"
            @clear="onClear" />
        <button
            id="searchSubmitButton"
            class="input-group-after btn ring-white"
            :class="props.isHome && 'w-20 !rounded-tr-md !rounded-br-md'"
            :title="$t('Layout.SearchButtonTitle')"
            @click="onSearchClick">
            <span
                class="fas fa-magnifying-glass text-gray-400"
                :class="props.isHome ? 'text-2xl' : 'text-lg'"
                aria-hidden="true" />
        </button>
    </div>
</template>

<script setup lang="ts">
import type { AutocompleteSearchResponseItem } from "~/composables/search";

const globalConfig = useStateGlobalConfig();

const search = useSearch();
const props = defineProps({
    isHome: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    region: {
        type: String,
        default: null,
    },
});

const searchResults: globalThis.Ref<AutocompleteSearchResponseItem[]> = ref([]);
const searchText = ref("");

const headers = {
    1: "Search.Autocomplete_Header_PartNumber",
    2: "Search.Autocomplete_Header_PartWithManufacturer",
    3: "Search.Autocomplete_Header_Manufacturer",
    4: "Search.Autocomplete_Header_PartCategory",
    5: "Search.Autocomplete_Header_ProductSeries",
};

function onClear() {
    searchResults.value = [];
}

let pendingSearchId = null;
async function onSearch(searchTerm: string) {
    const searchId = Date.now();
    pendingSearchId = searchId;
    const result = await search.getSearchResults(searchTerm);

    // Only allow the most recent pending search to update the results
    if (pendingSearchId === searchId) {
        searchResults.value = result;
    }
}

async function onSelect(item: AutocompleteSearchResponseItem) {
    searchText.value = item.label;
    const result = searchResults.value.find((x) => x.label == item.label && x.category == item.category);
    // only manufacturer and part category results will go directly to specific urls, everything else treat as a search.
    if (result && result.url) {
        await navigateTo(result.url, { external: true });
    } else {
        executeSearch(item.label);
    }
}

function onSearchClick() {
    search.execute(searchText.value);
}

function executeSearch(searchTerm: string) {
    search.execute(searchTerm);
}

onMounted(() => {
    searchText.value = globalConfig.value.SearchText;
});
</script>
