import type { LocaleObject } from "@nuxtjs/i18n";

export default function () {
    const { locale, locales } = useI18n();
    const switchLocalePath = useSwitchLocalePath();

    return computed<LocaleObject | null>({
        get: (): LocaleObject | null => locales.value.find((l) => l.code == locale.value) || null,
        set: async (newLocale: LocaleObject | null) => {
            if (!newLocale) return;

            const body = new FormData();
            body.append("ShortCode", newLocale.code);
            await $fetch("/api/locale-service/SetLanguage", { method: "POST", body });
            reloadNuxtApp({ path: switchLocalePath(newLocale.code), force: true });
        },
    });
}
