<template>
    <div class="input-group input-group-with-button h-10 grow transition-width">
        <Autocomplete
            id="searchTextMobileInput"
            v-model="searchText"
            :placeholder="$t('Search.FilterSearch_Watermark')"
            :headers="headers"
            :autofocus="props.isHome"
            :select-on-focus="true"
            custom-list-class="search-menu-list"
            custom-main-class="flex h-full w-full"
            :custom-container-class="props.isHome ? 'home-search-dropdown top-10' : 'search-dropdown top-10'"
            custom-class="h-10 text-sm border-white focus:border-white focus:ring-transparent"
            :show-min-length-message="true"
            :results="searchResults"
            @search="onSearch"
            @select="onSelect"
            @enter="executeSearch"
            @clear="onClear"
            @focus="onFocus"
            @blur="onBlur" />
        <button
            id="searchSubmitButtonMobile"
            class="input-group-after btn h-auto right-0.5 inset-y-px ring-white focus:ring-white"
            :title="$t('Layout.SearchButtonTitle')"
            @click="onSearchClick">
            <span class="fas fa-magnifying-glass text-gray-400" aria-hidden="true" />
        </button>
    </div>
</template>

<script setup lang="ts">
import type { AutocompleteSearchResponseItem } from "~/composables/search";

const globalConfig = useStateGlobalConfig();

const search = useSearch();
const props = defineProps({
    isHome: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    region: {
        type: String,
        default: null,
    },
});

const searchResults: globalThis.Ref<AutocompleteSearchResponseItem[]> = ref([]);
const searchText = ref("");

const headers = {
    1: "Search.Autocomplete_Header_PartNumber",
    2: "Search.Autocomplete_Header_PartWithManufacturer",
    3: "Search.Autocomplete_Header_Manufacturer",
    4: "Search.Autocomplete_Header_PartCategory",
    5: "Search.Autocomplete_Header_ProductSeries",
};

function onClear() {
    searchResults.value = [];
}

let pendingSearchId = null;
async function onSearch() {
    const searchId = Date.now();
    pendingSearchId = searchId;
    const result = await search.getSearchResults(searchText.value);

    // Only allow the most recent pending search to update the results
    if (pendingSearchId === searchId) {
        searchResults.value = result;
    }
}

async function onSelect(item: AutocompleteSearchResponseItem) {
    searchText.value = item.label;
    const result = searchResults.value.find((x) => x.label == item.label && x.category == item.category);
    // only manufacturer and part category results will go directly to specific urls, everything else treat as a search.
    if (result?.url) {
        await navigateTo(result.url, { external: true });
    } else {
        executeSearch(searchText.value);
    }
}

function onSearchClick() {
    search.execute(searchText.value);
}

function executeSearch(searchTerm: string) {
    search.execute(searchTerm);
}

function onFocus() {
    const innerNav = document.querySelector("nav.inner");
    if (innerNav) {
        innerNav.classList.add("open");
    }
}

function onBlur() {
    const innerNav = document.querySelector("nav.inner");
    if (innerNav) {
        innerNav.classList.remove("open");
    }
}

onMounted(() => {
    searchText.value = globalConfig.value.SearchText;
});
</script>
