<template>
    <DropdownMenuSub>
        <DropdownMenuSubTrigger data-test-id="language-dropdown-button">
            {{ currentLocale.name }}
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
            <DropdownMenuSubContent>
                <DropdownMenuItem v-for="locale in locales" :key="locale.label" as-child>
                    <a
                        :id="'language-option-' + locale.code"
                        class="link-gray"
                        :href="switchLocalePath(locale.code)"
                        @click.prevent="currentLocale = locale">
                        {{ locale.name }}
                    </a>
                </DropdownMenuItem>
            </DropdownMenuSubContent>
        </DropdownMenuPortal>
    </DropdownMenuSub>
</template>
<script setup>
const { locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const currentLocale = useCurrentLocale();
</script>
