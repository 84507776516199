<template>
    <DropdownMenu>
        <HeaderMenuTrigger data-test-id="language-dropdown-button">
            {{ currentLocale.name }}
        </HeaderMenuTrigger>
        <DropdownMenuContent>
            <DropdownMenuItem v-for="locale in locales" :key="locale.label" as-child>
                <a
                    :id="'language-option-' + locale.code"
                    class="link-gray justify-center"
                    :href="switchLocalePath(locale.code)"
                    @click.prevent="currentLocale = locale">
                    {{ locale.name }}
                </a>
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
<script setup>
const { locales } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const currentLocale = useCurrentLocale();
</script>
