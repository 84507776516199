<template>
    <DropdownMenu>
        <DropdownMenuTrigger class="group text-white" data-test-id="region-dropdown-button">
            {{ $t("RegionSelector.ChangeRegion") }}
            <i class="mx-1 inline-block fas fa-caret-down group-data-[state=open]:rotate-180"></i>
            {{ currentRegion.ShortName }}
        </DropdownMenuTrigger>
        <DropdownMenuContent class="w-40">
            <DropdownMenuItem
                v-for="region of regions"
                :id="'region-option-' + region.ShortName"
                :key="region.ShortName"
                class="link-gray justify-center"
                @click="currentRegion = region">
                {{ region.Name }}
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
<script setup>
const regions = useStateRegionInfo();
const currentRegion = useCurrentRegion();
</script>
