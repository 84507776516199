<template>
    <DropdownMenu>
        <DropdownMenuTrigger>
            <span class="sr-only">Toggle navigation</span>
            <span class="fas fa-bars text-white text-2xl"></span>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
            <HeaderRegionsSubMenu />
            <HeaderLanguageSubMenu />
            <HeaderCurrenciesSubMenu />
            <template v-if="isAuthenticated">
                <DropdownMenuItem v-for="link in accountMenuLinks" :key="link.href" as-child>
                    <a class="link-gray" v-bind="link">{{ link.title }}</a>
                </DropdownMenuItem>
            </template>
            <DropdownMenuItem v-else as-child>
                <a
                    id="sign-in-button-mobile"
                    :href="localePath('/sign-in')"
                    :title="$t('Layout.LinkLogin')"
                    class="link-gray">
                    {{ $t("Layout.LinkLogin") }}
                </a>
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
</template>
<script setup>
const isAuthenticated = useStateIsAuthenticated();
const localePath = useLangPath();
const accountMenuLinks = useAccountLinks();
</script>
