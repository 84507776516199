<template>
    <DropdownMenuSub>
        <DropdownMenuSubTrigger>
            {{ $t("RegionSelector.ChangeRegion") }}
            <span v-if="currentRegion">({{ currentRegion.ShortName }})</span>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
            <DropdownMenuSubContent>
                <DropdownMenuItem
                    v-for="region of regions"
                    :id="'region-option-' + region.ShortName"
                    :key="region.ShortName"
                    class="link-gray"
                    @click="currentRegion = region">
                    {{ region.Name }}
                </DropdownMenuItem>
            </DropdownMenuSubContent>
        </DropdownMenuPortal>
    </DropdownMenuSub>
</template>
<script setup>
const regions = useStateRegionInfo();
const currentRegion = useCurrentRegion();
</script>
